<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="formInline.time"
            clearable
            type="daterange"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          <el-button type="success" v-if="tableData.length>0" @click="exportXcle" icon="el-icon-download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox" :style="{height: maxHeight +'px' }">
      
      <el-table
      :data="tableData"
      style="width: 100%"
      id="exportTable"
      height="100%"
      show-summary
      >
        <el-table-column label="商户名称" width="150" align="center" fixed>
          <el-table-column prop="day" label="日期1" align="center" width="150"></el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in headLabel"
          :key="index"
          :label="item.name"
          :prop="item.name"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { merchantReportList } from '@/api/reportStatistics'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  data() {
    return {
      maxHeight: 0,
      choiceDate0: '',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate:
        (time) => {
          let choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 1);
          const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 1);
          const min = minTime;
          const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          const max = newDate < maxTime ? newDate : maxTime;
          //如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      formInline: {
        time: '',
      },
      tableData: [],
      headLabel: [],
    };
  },

  components: {},

  computed: {},

  created(){
    let innerHeight = window.innerHeight;
    this.maxHeight = innerHeight - 215;
    /* window.addEventListener("resize", function(){
      let innerHeight = window.innerHeight;
      this.maxHeight = innerHeight - 210;
      console.log(this.maxHeight)
    }); */
  },
  mounted() {
    this.getList();
  },

  methods: {
    onSearch() {
      this.getList();
    },
    //获取商户近一个月每天获取积分
    getList() {
      merchantReportList({
        startDate: this.formInline.time[0] || '',
        endDate: this.formInline.time[1] || ''
      }).then( res => {
        if(res.result == 200) {
          let detail = res.detail;
          this.tableData = detail.table;
          this.headLabel = detail.xline;
          this.handleList();
        }
      })
    },
    //处理数据
    handleList() {
      if(this.tableData.length > 0) {
        for(let i = 0; i < this.tableData.length; i++) {
          this.headLabel = this.tableData[i].data;
          for(let j = 0; j < this.tableData[i].data.length; j++) {
            this.tableData[i][this.tableData[i].data[j].name] = this.tableData[i].data[j].value;
          }
        }
      }
    },
    //导出表格
    exportXcle() {
      let gatherData = { raw: true };
      let grid = XLSX.utils.table_to_book(
        document.querySelector("#exportTable"),
        gatherData
      );
      let workbook = XLSX.write(grid, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([workbook], {
            type: "application/octet-stream",
          }),
          "商户积分统计.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, workbook);
      }
      return workbook;
    },
  },
};
</script>
<style lang="scss" scoped>
.tablebox {
  ::v-deep  .el-table thead.is-group th {
    padding: 0px;
  }

  ::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type {
    border-bottom: none;/*中间的横线去掉*/
  }

  ::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type div.cell {
      text-align: right;/*上边文字靠右*/
  }

  ::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type div.cell {
      text-align: left;/*下边文字靠左*/
  }
	/*核心代码*/
  ::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type:before {
      content: "";
      position: absolute;
      width: 1px;
      height: 100px;/*斜线的长度*/
      top: 0;
      left: 0;
      background-color: grey;
      opacity: 0.2;
      display: block;
      transform: rotate(-43deg);/*调整斜线的角度*/
      transform: rotate(-70deg);/*调整斜线的角度*/
      -webkit-transform-origin: top;
      transform-origin: top;
  }

  ::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type:before {
      content: "";
      position: absolute;
      width: 1px;
      height: 100px;/*斜线的长度*/
      bottom: 0;
      right: 0;
      background-color: grey;
      opacity: 0.2;
      display: block;
      transform: rotate(-45deg);/*调整斜线的角度*/
      transform: rotate(-70deg);/*调整斜线的角度*/
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
  }
  ::v-deep .el-table thead.is-group th{
      height: 27.4px;
  }
}
</style>
